import React from "react"
import RacepageTdF from "../components/racepageOneDay"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Olympic Games RR",
      edition: 2021,
      what: "Race data",
      updated: "2021-07-23 13:05:53",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      Algeria: {
        teamId: "Algeria",
        teamUciCode: "",
        teamName: "Algeria",
        teamNationCode: "ALG",
      },
      Argentina: {
        teamId: "Argentina",
        teamUciCode: "",
        teamName: "Argentina",
        teamNationCode: "ARG",
      },
      Australia: {
        teamId: "Australia",
        teamUciCode: "",
        teamName: "Australia",
        teamNationCode: "AUS",
      },
      Austria: {
        teamId: "Austria",
        teamUciCode: "",
        teamName: "Austria",
        teamNationCode: "AUT",
      },
      Azerbaijan: {
        teamId: "Azerbaijan",
        teamUciCode: "",
        teamName: "Azerbaijan",
        teamNationCode: "AZE",
      },
      Belarus: {
        teamId: "Belarus",
        teamUciCode: "",
        teamName: "Belarus",
        teamNationCode: "BLR",
      },
      Belgium: {
        teamId: "Belgium",
        teamUciCode: "",
        teamName: "Belgium",
        teamNationCode: "BEL",
      },
      "Burkina Faso": {
        teamId: "Burkina Faso",
        teamUciCode: "",
        teamName: "Burkina Faso",
        teamNationCode: "BFA",
      },
      Canada: {
        teamId: "Canada",
        teamUciCode: "",
        teamName: "Canada",
        teamNationCode: "CAN",
      },
      China: {
        teamId: "China",
        teamUciCode: "",
        teamName: "China",
        teamNationCode: "CHN",
      },
      Colombia: {
        teamId: "Colombia",
        teamUciCode: "",
        teamName: "Colombia",
        teamNationCode: "COL",
      },
      "Costa Rica": {
        teamId: "Costa Rica",
        teamUciCode: "",
        teamName: "Costa Rica",
        teamNationCode: "CRC",
      },
      Croatia: {
        teamId: "Croatia",
        teamUciCode: "",
        teamName: "Croatia",
        teamNationCode: "CRO",
      },
      "Czech Republic": {
        teamId: "Czech Republic",
        teamUciCode: "",
        teamName: "Czech Republic",
        teamNationCode: "CZE",
      },
      Denmark: {
        teamId: "Denmark",
        teamUciCode: "",
        teamName: "Denmark",
        teamNationCode: "DEN",
      },
      Ecuador: {
        teamId: "Ecuador",
        teamUciCode: "",
        teamName: "Ecuador",
        teamNationCode: "ECU",
      },
      Eritrea: {
        teamId: "Eritrea",
        teamUciCode: "",
        teamName: "Eritrea",
        teamNationCode: "ERI",
      },
      Estonia: {
        teamId: "Estonia",
        teamUciCode: "",
        teamName: "Estonia",
        teamNationCode: "EST",
      },
      France: {
        teamId: "France",
        teamUciCode: "",
        teamName: "France",
        teamNationCode: "FRA",
      },
      Germany: {
        teamId: "Germany",
        teamUciCode: "",
        teamName: "Germany",
        teamNationCode: "GER",
      },
      "Great Britain": {
        teamId: "Great Britain",
        teamUciCode: "",
        teamName: "Great Britain",
        teamNationCode: "GBR",
      },
      Greece: {
        teamId: "Greece",
        teamUciCode: "",
        teamName: "Greece",
        teamNationCode: "GRE",
      },
      Guatemala: {
        teamId: "Guatemala",
        teamUciCode: "",
        teamName: "Guatemala",
        teamNationCode: "GUA",
      },
      "Hong Kong": {
        teamId: "Hong Kong",
        teamUciCode: "",
        teamName: "Hong Kong",
        teamNationCode: "HKG",
      },
      Hungary: {
        teamId: "Hungary",
        teamUciCode: "",
        teamName: "Hungary",
        teamNationCode: "HUN",
      },
      Iran: {
        teamId: "Iran",
        teamUciCode: "",
        teamName: "Iran",
        teamNationCode: "IRN",
      },
      Ireland: {
        teamId: "Ireland",
        teamUciCode: "",
        teamName: "Ireland",
        teamNationCode: "IRL",
      },
      Italy: {
        teamId: "Italy",
        teamUciCode: "",
        teamName: "Italy",
        teamNationCode: "ITA",
      },
      Japan: {
        teamId: "Japan",
        teamUciCode: "",
        teamName: "Japan",
        teamNationCode: "JPN",
      },
      Kazakhstan: {
        teamId: "Kazakhstan",
        teamUciCode: "",
        teamName: "Kazakhstan",
        teamNationCode: "KAZ",
      },
      Latvia: {
        teamId: "Latvia",
        teamUciCode: "",
        teamName: "Latvia",
        teamNationCode: "LAT",
      },
      Lithuania: {
        teamId: "Lithuania",
        teamUciCode: "",
        teamName: "Lithuania",
        teamNationCode: "LTU",
      },
      Luxembourg: {
        teamId: "Luxembourg",
        teamUciCode: "",
        teamName: "Luxembourg",
        teamNationCode: "LUX",
      },
      Mexico: {
        teamId: "Mexico",
        teamUciCode: "",
        teamName: "Mexico",
        teamNationCode: "MEX",
      },
      Morocco: {
        teamId: "Morocco",
        teamUciCode: "",
        teamName: "Morocco",
        teamNationCode: "MAR",
      },
      Namibia: {
        teamId: "Namibia",
        teamUciCode: "",
        teamName: "Namibia",
        teamNationCode: "NAM",
      },
      Netherlands: {
        teamId: "Netherlands",
        teamUciCode: "",
        teamName: "Netherlands",
        teamNationCode: "NED",
      },
      "New Zealand": {
        teamId: "New Zealand",
        teamUciCode: "",
        teamName: "New Zealand",
        teamNationCode: "NZL",
      },
      Norway: {
        teamId: "Norway",
        teamUciCode: "",
        teamName: "Norway",
        teamNationCode: "NOR",
      },
      Panama: {
        teamId: "Panama",
        teamUciCode: "",
        teamName: "Panama",
        teamNationCode: "PAN",
      },
      Peru: {
        teamId: "Peru",
        teamUciCode: "",
        teamName: "Peru",
        teamNationCode: "PER",
      },
      Poland: {
        teamId: "Poland",
        teamUciCode: "",
        teamName: "Poland",
        teamNationCode: "POL",
      },
      Portugal: {
        teamId: "Portugal",
        teamUciCode: "",
        teamName: "Portugal",
        teamNationCode: "POR",
      },
      Romania: {
        teamId: "Romania",
        teamUciCode: "",
        teamName: "Romania",
        teamNationCode: "ROM",
      },
      Russia: {
        teamId: "Russia",
        teamUciCode: "",
        teamName: "Russia",
        teamNationCode: "RUS",
      },
      Rwanda: {
        teamId: "Rwanda",
        teamUciCode: "",
        teamName: "Rwanda",
        teamNationCode: "RWA",
      },
      Slovakia: {
        teamId: "Slovakia",
        teamUciCode: "",
        teamName: "Slovakia",
        teamNationCode: "SVK",
      },
      Slovenia: {
        teamId: "Slovenia",
        teamUciCode: "",
        teamName: "Slovenia",
        teamNationCode: "SLO",
      },
      "South Africa": {
        teamId: "South Africa",
        teamUciCode: "",
        teamName: "South Africa",
        teamNationCode: "RSA",
      },
      Spain: {
        teamId: "Spain",
        teamUciCode: "",
        teamName: "Spain",
        teamNationCode: "ESP",
      },
      Switzerland: {
        teamId: "Switzerland",
        teamUciCode: "",
        teamName: "Switzerland",
        teamNationCode: "SUI",
      },
      Taiwan: {
        teamId: "Taiwan",
        teamUciCode: "",
        teamName: "Taiwan",
        teamNationCode: "TPE",
      },
      Turkey: {
        teamId: "Turkey",
        teamUciCode: "",
        teamName: "Turkey",
        teamNationCode: "TUR",
      },
      Ukraine: {
        teamId: "Ukraine",
        teamUciCode: "",
        teamName: "Ukraine",
        teamNationCode: "UKR",
      },
      USA: {
        teamId: "USA",
        teamUciCode: "",
        teamName: "USA",
        teamNationCode: "USA",
      },
      Uzbekistan: {
        teamId: "Uzbekistan",
        teamUciCode: "",
        teamName: "Uzbekistan",
        teamNationCode: "UZB",
      },
      Venezuela: {
        teamId: "Venezuela",
        teamUciCode: "",
        teamName: "Venezuela",
        teamNationCode: "VEN",
      },
    },
    riders: {
      91362: {
        id: 91362,
        startno: 120,
        firstName: "Paul",
        lastName: "Daumont",
        nationCode: "BFA",
        nationName: "Burkina Faso",
        birthDate: "1999-09-01",
        teamName: "Burkina Faso",
        teamId: "Burkina Faso",
        stillInTheRace: "Y",
      },
      168: {
        id: 168,
        startno: 49,
        firstName: "Simon",
        lastName: "Geschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-03-13",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      3935: {
        id: 3935,
        startno: 30,
        firstName: "Yoeri",
        lastName: "Havik",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1991-02-19",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      872: {
        id: 872,
        startno: 17,
        firstName: "Gorka",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-10-07",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      58639: {
        id: 58639,
        startno: 35,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      14600: {
        id: 14600,
        startno: 113,
        firstName: "Manuel",
        lastName: "Rodas",
        nationCode: "GUA",
        nationName: "Guatemala",
        birthDate: "1984-07-25",
        teamName: "Guatemala",
        teamId: "Guatemala",
        stillInTheRace: "Y",
      },
      16807: {
        id: 16807,
        startno: 66,
        firstName: "Markus",
        lastName: "Hoelgaard",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-10-04",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      27048: {
        id: 27048,
        startno: 117,
        firstName: "Onur",
        lastName: "Balkan",
        nationCode: "TUR",
        nationName: "Turkey",
        birthDate: "1996-03-10",
        teamName: "Turkey",
        teamId: "Turkey",
        stillInTheRace: "Y",
      },
      4669: {
        id: 4669,
        startno: 82,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      20584: {
        id: 20584,
        startno: 24,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      3991: {
        id: 3991,
        startno: 71,
        firstName: "Rafal",
        lastName: "Majka",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1989-09-12",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      62831: {
        id: 62831,
        startno: 104,
        firstName: "Hamza",
        lastName: "Mansouri",
        nationCode: "ALG",
        nationName: "Algeria",
        birthDate: "1999-04-13",
        teamName: "Algeria",
        teamId: "Algeria",
        stillInTheRace: "Y",
      },
      563: {
        id: 563,
        startno: 42,
        firstName: "Richie",
        lastName: "Porte",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1985-01-30",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      32493: {
        id: 32493,
        startno: 96,
        firstName: "Amanuel",
        lastName: "Gebreigzabhier",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-08-17",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      5836: {
        id: 5836,
        startno: 90,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamName: "Kazakhstan",
        teamId: "Kazakhstan",
        stillInTheRace: "Y",
      },
      99787: {
        id: 99787,
        startno: 114,
        firstName: "Moise",
        lastName: "Mugisha",
        nationCode: "RWA",
        nationName: "Rwanda",
        birthDate: "1997-09-01",
        teamName: "Rwanda",
        teamId: "Rwanda",
        stillInTheRace: "Y",
      },
      84019: {
        id: 84019,
        startno: 3,
        firstName: "Remco",
        lastName: "Evenepoel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-01-25",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      37378: {
        id: 37378,
        startno: 13,
        firstName: "David",
        lastName: "Gaudu",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-10-10",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      46182: {
        id: 46182,
        startno: 101,
        firstName: "Michel",
        lastName: "Ries",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1998-03-11",
        teamName: "Luxembourg",
        teamId: "Luxembourg",
        stillInTheRace: "Y",
      },
      44340: {
        id: 44340,
        startno: 75,
        firstName: "Nicholas",
        lastName: "Dlamini",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1995-08-12",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      16569: {
        id: 16569,
        startno: 79,
        firstName: "Michael",
        lastName: "Woods",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1986-10-12",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      716: {
        id: 716,
        startno: 21,
        firstName: "Geraint",
        lastName: "Thomas",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1986-05-25",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      7724: {
        id: 7724,
        startno: 33,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      46163: {
        id: 46163,
        startno: 51,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      745: {
        id: 745,
        startno: 126,
        firstName: "Andrey",
        lastName: "Amador",
        nationCode: "CRC",
        nationName: "Costa Rica",
        birthDate: "1986-08-29",
        teamName: "Costa Rica",
        teamId: "Costa Rica",
        stillInTheRace: "Y",
      },
      27283: {
        id: 27283,
        startno: 57,
        firstName: "Edward",
        lastName: "Dunbar",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1996-09-01",
        teamName: "Ireland",
        teamId: "Ireland",
        stillInTheRace: "Y",
      },
      27448: {
        id: 27448,
        startno: 109,
        firstName: "Cristofer",
        lastName: "Jurado",
        nationCode: "PAN",
        nationName: "Panama",
        birthDate: "1995-10-27",
        teamName: "Panama",
        teamId: "Panama",
        stillInTheRace: "Y",
      },
      3355: {
        id: 3355,
        startno: 86,
        firstName: "Lawson",
        lastName: "Craddock",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-02-20",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      6309: {
        id: 6309,
        startno: 45,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      28781: {
        id: 28781,
        startno: 27,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      37280: {
        id: 37280,
        startno: 62,
        firstName: "Pavel",
        lastName: "Sivakov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1997-07-11",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      78950: {
        id: 78950,
        startno: 89,
        firstName: "Lucas",
        lastName: "Kubis",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "2000-01-31",
        teamName: "Slovakia",
        teamId: "Slovakia",
        stillInTheRace: "Y",
      },
      55807: {
        id: 55807,
        startno: 85,
        firstName: "Hermann",
        lastName: "Pernsteiner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1990-08-07",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      17516: {
        id: 17516,
        startno: 95,
        firstName: "Peeter",
        lastName: "Pruus",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1989-07-16",
        teamName: "Estonia",
        teamId: "Estonia",
        stillInTheRace: "Y",
      },
      16660: {
        id: 16660,
        startno: 98,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamName: "Latvia",
        teamId: "Latvia",
        stillInTheRace: "Y",
      },
      6538: {
        id: 6538,
        startno: 7,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      70034: {
        id: 70034,
        startno: 5,
        firstName: "Mauri",
        lastName: "Vansevenant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-06-01",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      9344: {
        id: 9344,
        startno: 48,
        firstName: "Emanuel",
        lastName: "Buchmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-18",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      2324: {
        id: 2324,
        startno: 29,
        firstName: "Tom",
        lastName: "Dumoulin",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-11-11",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      2325: {
        id: 2325,
        startno: 16,
        firstName: "Jesus",
        lastName: "Herrada",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-07-26",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      3213: {
        id: 3213,
        startno: 34,
        firstName: "Esteban",
        lastName: "Chaves",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-01-17",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      6579: {
        id: 6579,
        startno: 108,
        firstName: "Polychronis",
        lastName: "Tzortzakis",
        nationCode: "GRE",
        nationName: "Greece",
        birthDate: "1989-01-03",
        teamName: "Greece",
        teamId: "Greece",
        stillInTheRace: "Y",
      },
      37297: {
        id: 37297,
        startno: 65,
        firstName: "Tobias",
        lastName: "Foss",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-05-25",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      327: {
        id: 327,
        startno: 54,
        firstName: "Michael",
        lastName: "Sch\u00e4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      16608: {
        id: 16608,
        startno: 81,
        firstName: "Michal",
        lastName: "Schlegel",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1995-05-31",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      10049: {
        id: 10049,
        startno: 122,
        firstName: "Saeid",
        lastName: "Safarzadeh",
        nationCode: "IRN",
        nationName: "Iran",
        birthDate: "1985-09-21",
        teamName: "Iran",
        teamId: "Iran",
        stillInTheRace: "Y",
      },
      2961: {
        id: 2961,
        startno: 70,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      37221: {
        id: 37221,
        startno: 116,
        firstName: "Orluis",
        lastName: "Aular",
        nationCode: "VEN",
        nationName: "Venezuela",
        birthDate: "1996-11-05",
        teamName: "Venezuela",
        teamId: "Venezuela",
        stillInTheRace: "Y",
      },
      37445: {
        id: 37445,
        startno: 41,
        firstName: "Lucas",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-12",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      41249: {
        id: 41249,
        startno: 61,
        firstName: "Jhonnatan",
        lastName: "Narvaez",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1997-03-04",
        teamName: "Ecuador",
        teamId: "Ecuador",
        stillInTheRace: "Y",
      },
      9377: {
        id: 9377,
        startno: 112,
        firstName: "Nariyuki",
        lastName: "Masuda",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1983-10-23",
        teamName: "Japan",
        teamId: "Japan",
        stillInTheRace: "Y",
      },
      14778: {
        id: 14778,
        startno: 105,
        firstName: "Eduard Michael",
        lastName: "Grosu",
        nationCode: "ROM",
        nationName: "Romania",
        birthDate: "1992-09-04",
        teamName: "Romania",
        teamId: "Romania",
        stillInTheRace: "Y",
      },
      16786: {
        id: 16786,
        startno: 2,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      4004: {
        id: 4004,
        startno: 12,
        firstName: "Kenny",
        lastName: "Elissonde",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-07-22",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      37325: {
        id: 37325,
        startno: 100,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamName: "Luxembourg",
        teamId: "Luxembourg",
        stillInTheRace: "Y",
      },
      44341: {
        id: 44341,
        startno: 74,
        firstName: "Stefan",
        lastName: "de Bod",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-11-17",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      3199: {
        id: 3199,
        startno: 78,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      16639: {
        id: 16639,
        startno: 20,
        firstName: "Tao",
        lastName: "Geoghegan Hart",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-03-30",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      581: {
        id: 581,
        startno: 32,
        firstName: "Bauke",
        lastName: "Mollema",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-11-26",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      38: {
        id: 38,
        startno: 19,
        firstName: "Alejandro",
        lastName: "Valverde",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1980-04-25",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      117: {
        id: 117,
        startno: 38,
        firstName: "Rigoberto",
        lastName: "Uran",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-01-26",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      69890: {
        id: 69890,
        startno: 107,
        firstName: "Attila",
        lastName: "Valter",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-06-12",
        teamName: "Hungary",
        teamId: "Hungary",
        stillInTheRace: "Y",
      },
      66973: {
        id: 66973,
        startno: 68,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-05-21",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      17008: {
        id: 17008,
        startno: 93,
        firstName: "Anatolii",
        lastName: "Budiak",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1995-09-29",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      264: {
        id: 264,
        startno: 44,
        firstName: "Jakob",
        lastName: "Fuglsang",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-03-22",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      20384: {
        id: 20384,
        startno: 26,
        firstName: "Giulio",
        lastName: "Ciccone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-12-20",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      1008: {
        id: 1008,
        startno: 56,
        firstName: "Nelson",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1989-03-06",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      44361: {
        id: 44361,
        startno: 115,
        firstName: "Tristan",
        lastName: "De Lange",
        nationCode: "NAM",
        nationName: "Namibia",
        birthDate: "1997-06-15",
        teamName: "Namibia",
        teamId: "Namibia",
        stillInTheRace: "Y",
      },
      16578: {
        id: 16578,
        startno: 84,
        firstName: "Gregor",
        lastName: "Muhlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1994-04-04",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      1018: {
        id: 1018,
        startno: 94,
        firstName: "Tanel",
        lastName: "Kangert",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-03-11",
        teamName: "Estonia",
        teamId: "Estonia",
        stillInTheRace: "Y",
      },
      45445: {
        id: 45445,
        startno: 92,
        firstName: "Vadim",
        lastName: "Pronskiy",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1998-06-04",
        teamName: "Kazakhstan",
        teamId: "Kazakhstan",
        stillInTheRace: "Y",
      },
      45992: {
        id: 45992,
        startno: 6,
        firstName: "Tadej",
        lastName: "Pogacar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1998-09-21",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      150: {
        id: 150,
        startno: 1,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      5306: {
        id: 5306,
        startno: 47,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      37320: {
        id: 37320,
        startno: 125,
        firstName: "Mohcine",
        lastName: "El Kouraji",
        nationCode: "MAR",
        nationName: "Morocco",
        birthDate: "1997-12-01",
        teamName: "Morocco",
        teamId: "Morocco",
        stillInTheRace: "Y",
      },
      7640: {
        id: 7640,
        startno: 15,
        firstName: "Omar",
        lastName: "Fraile",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-07-17",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      9014: {
        id: 9014,
        startno: 129,
        firstName: "Chun Kai",
        lastName: "Feng",
        nationCode: "TPE",
        nationName: "Taiwan",
        birthDate: "1988-11-02",
        teamName: "Taiwan",
        teamId: "Taiwan",
        stillInTheRace: "Y",
      },
      6242: {
        id: 6242,
        startno: 22,
        firstName: "Simon",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      3287: {
        id: 3287,
        startno: 73,
        firstName: "Patrick",
        lastName: "Bevin",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1991-05-02",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      37259: {
        id: 37259,
        startno: 53,
        firstName: "Gino",
        lastName: "M\u00e4der",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-01-04",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      16211: {
        id: 16211,
        startno: 80,
        firstName: "Michael",
        lastName: "Kukrle",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1994-11-17",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      189: {
        id: 189,
        startno: 59,
        firstName: "Nicolas",
        lastName: "Roche",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1984-07-03",
        teamName: "Ireland",
        teamId: "Ireland",
        stillInTheRace: "Y",
      },
      361: {
        id: 361,
        startno: 69,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      9250: {
        id: 9250,
        startno: 124,
        firstName: "Muradjan",
        lastName: "Halmuratov ",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "1982-06-11",
        teamName: "Uzbekistan",
        teamId: "Uzbekistan",
        stillInTheRace: "Y",
      },
      9451: {
        id: 9451,
        startno: 123,
        firstName: "Eduardo",
        lastName: "Sepulveda",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1991-06-13",
        teamName: "Argentina",
        teamId: "Argentina",
        stillInTheRace: "Y",
      },
      23097: {
        id: 23097,
        startno: 60,
        firstName: "Richard",
        lastName: "Carapaz",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-05-29",
        teamName: "Ecuador",
        teamId: "Ecuador",
        stillInTheRace: "Y",
      },
      163: {
        id: 163,
        startno: 111,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamName: "Japan",
        teamId: "Japan",
        stillInTheRace: "Y",
      },
      9128: {
        id: 9128,
        startno: 64,
        firstName: "Ilnur",
        lastName: "Zakarin",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1989-09-15",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      16587: {
        id: 16587,
        startno: 102,
        firstName: "Alexandr",
        lastName: "Riabushenko",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1995-10-12",
        teamName: "Belarus",
        teamId: "Belarus",
        stillInTheRace: "Y",
      },
      34011: {
        id: 34011,
        startno: 11,
        firstName: "Beno\u00eet",
        lastName: "Cosnefroy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-10-17",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      7755: {
        id: 7755,
        startno: 106,
        firstName: "Evaldas",
        lastName: "Siskevicius",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1988-12-30",
        teamName: "Lithuania",
        teamId: "Lithuania",
        stillInTheRace: "Y",
      },
      3050: {
        id: 3050,
        startno: 9,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      1937: {
        id: 1937,
        startno: 77,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      16643: {
        id: 16643,
        startno: 50,
        firstName: "Maximilian",
        lastName: "Schachmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-09",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      2165: {
        id: 2165,
        startno: 31,
        firstName: "Wilco",
        lastName: "Kelderman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1991-03-25",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      2977: {
        id: 2977,
        startno: 18,
        firstName: "Ion",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-02-04",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      2276: {
        id: 2276,
        startno: 37,
        firstName: "Nairo",
        lastName: "Quintana",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-02-04",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      59389: {
        id: 59389,
        startno: 130,
        firstName: "Hiu Fung",
        lastName: "Choy",
        nationCode: "HKG",
        nationName: "Hong Kong",
        birthDate: "1996-01-17",
        teamName: "Hong Kong",
        teamId: "Hong Kong",
        stillInTheRace: "Y",
      },
      87900: {
        id: 87900,
        startno: 67,
        firstName: "Tobias Halland",
        lastName: "Johannessen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-08-23",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      9149: {
        id: 9149,
        startno: 118,
        firstName: "Ahmet",
        lastName: "\u00d6rken",
        nationCode: "TUR",
        nationName: "Turkey",
        birthDate: "1993-03-12",
        teamName: "Turkey",
        teamId: "Turkey",
        stillInTheRace: "Y",
      },
      27121: {
        id: 27121,
        startno: 43,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      1006: {
        id: 1006,
        startno: 25,
        firstName: "Damiano",
        lastName: "Caruso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-10-12",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      50303: {
        id: 50303,
        startno: 55,
        firstName: "Joao",
        lastName: "Almeida",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1998-08-05",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      2327: {
        id: 2327,
        startno: 40,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      6245: {
        id: 6245,
        startno: 83,
        firstName: "Patrick",
        lastName: "Konrad",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-10-13",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      18117: {
        id: 18117,
        startno: 97,
        firstName: "Merhawi",
        lastName: "Kudus",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-01-23",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      6230: {
        id: 6230,
        startno: 91,
        firstName: "Alexey",
        lastName: "Lutsenko",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1992-09-07",
        teamName: "Kazakhstan",
        teamId: "Kazakhstan",
        stillInTheRace: "Y",
      },
      2172: {
        id: 2172,
        startno: 88,
        firstName: "Juraj",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1988-12-23",
        teamName: "Slovakia",
        teamId: "Slovakia",
        stillInTheRace: "Y",
      },
      18258: {
        id: 18258,
        startno: 4,
        firstName: "Wout",
        lastName: "Van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      10990: {
        id: 10990,
        startno: 14,
        firstName: "Guillaume",
        lastName: "Martin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-06-09",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      16910: {
        id: 16910,
        startno: 110,
        firstName: "Eder",
        lastName: "Frayre",
        nationCode: "MEX",
        nationName: "Mexico",
        birthDate: "1991-10-20",
        teamName: "Mexico",
        teamId: "Mexico",
        stillInTheRace: "Y",
      },
      16998: {
        id: 16998,
        startno: 76,
        firstName: "Ryan",
        lastName: "Gibbons",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1994-08-13",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      160784: {
        id: 160784,
        startno: 121,
        firstName: "Ruidong",
        lastName: "Wang",
        nationCode: "CHN",
        nationName: "China",
        birthDate: "2000-07-08",
        teamName: "China",
        teamId: "China",
        stillInTheRace: "Y",
      },
      20147: {
        id: 20147,
        startno: 23,
        firstName: "Adam",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      3352: {
        id: 3352,
        startno: 72,
        firstName: "George",
        lastName: "Bennett",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-04-07",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      16724: {
        id: 16724,
        startno: 52,
        firstName: "Stefan",
        lastName: "K\u00fcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      16605: {
        id: 16605,
        startno: 119,
        firstName: "Josip",
        lastName: "Rumac",
        nationCode: "CRO",
        nationName: "Croatia",
        birthDate: "1994-10-26",
        teamName: "Croatia",
        teamId: "Croatia",
        stillInTheRace: "Y",
      },
      51: {
        id: 51,
        startno: 58,
        firstName: "Daniel",
        lastName: "Martin",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1986-08-20",
        teamName: "Ireland",
        teamId: "Ireland",
        stillInTheRace: "Y",
      },
      23099: {
        id: 23099,
        startno: 128,
        firstName: "Royner",
        lastName: "Navarro",
        nationCode: "PER",
        nationName: "Peru",
        birthDate: "1992-08-01",
        teamName: "Peru",
        teamId: "Peru",
        stillInTheRace: "Y",
      },
      48539: {
        id: 48539,
        startno: 87,
        firstName: "Brandon",
        lastName: "McNulty",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1998-04-02",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      6422: {
        id: 6422,
        startno: 103,
        firstName: "Azzedine",
        lastName: "Lagab",
        nationCode: "ALG",
        nationName: "Algeria",
        birthDate: "1988-06-19",
        teamName: "Algeria",
        teamId: "Algeria",
        stillInTheRace: "Y",
      },
      1715: {
        id: 1715,
        startno: 46,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      133: {
        id: 133,
        startno: 28,
        firstName: "Vincenzo",
        lastName: "Nibali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-11-14",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      37281: {
        id: 37281,
        startno: 63,
        firstName: "Aleksandr",
        lastName: "Vlasov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-04-23",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      9341: {
        id: 9341,
        startno: 127,
        firstName: "Elchin",
        lastName: "Asadov",
        nationCode: "AZE",
        nationName: "Azerbaijan",
        birthDate: "1987-02-12",
        teamName: "Azerbaijan",
        teamId: "Azerbaijan",
        stillInTheRace: "Y",
      },
      27111: {
        id: 27111,
        startno: 10,
        firstName: "Remi",
        lastName: "Cavagna",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-08-10",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      3930: {
        id: 3930,
        startno: 99,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamName: "Latvia",
        teamId: "Latvia",
        stillInTheRace: "Y",
      },
      18655: {
        id: 18655,
        startno: 8,
        firstName: "Primoz",
        lastName: "Roglic",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-10-29",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Olympics RR Men"
  const raceID = 33

  return (
    <RacepageTdF
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
